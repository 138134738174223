import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Credentials } from 'src/models/credentials.model';

export interface CreateCredentialsResponse {
  message: string;
}
@Injectable()
export class CredentialsService {
  private readonly http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  // TODO: temp, shore this up
  createCredentials = (newCredentials: Credentials & { credential_value: string }) =>
    this.http.post<CreateCredentialsResponse>(`${this.apiUrl}user-credentials`, newCredentials);

  deleteCredentials = (credentialsId: number) =>
    this.http.delete<unknown>(`${this.apiUrl}user-credentials/${credentialsId}`);
}
